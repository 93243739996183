import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyComponent } from '@features/landing/privacy/privacy.component';
import { TermsComponent } from '@features/landing/terms/terms.component';
import { NotFoundComponent } from '@features/not-found/not-found.component';
import { SessionGuard } from '@features/session/session.guard';
import { SubscriptionComponent } from '@features/landing/subscription/subscription.component';
import { RoleGuard } from './features/session/role.guard';
import { LoginComponent } from '@features/session/login/login.component';
import { EquationEditorComponent } from '@libs/equation-editor/equation-editor.component';

const routes: Routes = [
  {
    path: 'social-document',
    loadChildren: () =>
      import('./features/social-document/social-document.module').then(
        (m) => m.SocialDocumentModule
      ),
    canLoad: [SessionGuard],
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./features/quiz/quiz.module').then((m) => m.QuizModule),
    canLoad: [SessionGuard],
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./features/quiz/quiz.module').then((m) => m.QuizModule),
    canLoad: [SessionGuard],
  },
  {
    path: 'quiz-game',
    loadChildren: () =>
      import('./features/quiz/quiz.module').then((m) => m.QuizModule),
    canLoad: [SessionGuard],
  },
  {
    path: 'interactive-video',
    loadChildren: () =>
      import('./features/interactive-video/interactive-video.module').then(
        (m) => m.InteractiveVideoModule
      ),
    canLoad: [SessionGuard],
  },
  {
    path: 'tutorials',
    loadChildren: () =>
      import('./features/tutorial-content/tutorial-content.module').then(
        (m) => m.TutorialContentModule
      ),
    canLoad: [SessionGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/session/session.module').then((m) => m.SessionModule),
  },
  {
    path: 'assignment',
    loadChildren: () =>
      import('./features/assignment/assignment.module').then(
        (m) => m.AssignmentModule
      ),
    canLoad: [SessionGuard],
    data: { feature: 'assignment' },
  },
  {
    path: 'flashcards',
    loadChildren: () =>
      import('./features/flashcards/flashcards.module').then(
        (m) => m.FlashcardsModule
      ),
    canLoad: [SessionGuard],
    data: { feature: 'flashcards' },
  },
  {
    path: 'crossword',
    loadChildren: () =>
      import('./features/crossword/crossword.module').then(
        (m) => m.CrosswordModule
      ),
    canLoad: [SessionGuard],
    data: { feature: 'crossword' },
  },
  {
    path: 'materials',
    loadChildren: () =>
      import('./features/materials/materials.module').then(
        (m) => m.MaterialsModule
      ),
    canLoad: [SessionGuard],
    data: { feature: 'materials' },
  },
  {
    path: 'mindmap',
    loadChildren: () =>
      import('./features/mindmap/mindmap.module').then((m) => m.MindmapModule),
    canLoad: [SessionGuard],
    data: { feature: 'mindmap' },
  },
  {
    path: 'simulations',
    loadChildren: () =>
      import('./features/simulations/simulations.module').then(
        (m) => m.SimulationsModule
      ),
    canLoad: [SessionGuard],
  },
  { path: 'libs/equation-editor', component: EquationEditorComponent },
  {
    path: '',
    component: LoginComponent, // eagerly loading landing page
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'webinar',
    component: SubscriptionComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canLoad: [SessionGuard],
  },
  {
    path: 'admin-dashboard',
    loadChildren: () =>
      import('./features/admin-dashboard/admin-dashboard.module').then(
        (m) => m.AdminDashboardModule
      ),
    canLoad: [SessionGuard, RoleGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () =>
      import('./features/super-admin/super-admin.module').then(
        (m) => m.SuperAdminModule
      ),
    canLoad: [SessionGuard, RoleGuard],
  },

  {
    path: 'contact',
    loadChildren: () =>
      import('./features/landing/contact/contact.module').then(
        (m) => m.ContactModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
