import { ApiResponseItem } from '@core/interfaces/api-response.interface';

export interface CognitoError {
  code: string;
  name: string;
  message: string;
}
export interface CognitoUser {
  username: string;
  pool: Pool;
  Session: null;
  client: Client;
  signInUserSession: SignInUserSession;
  authenticationFlowType: string;
  storage: { [key: string]: string };
  keyPrefix: string;
  userDataKey: string;
  attributes?: Attributes;
  preferredMFA: string;
  challengeName?: ChallengeName;
  challengeParam?: ChallengeParam;
}
export interface registerPayload {
  email: string;
  name: string;
  familyName: string;
  classCode?: string;
  roleId?: number;
}
export type ChallengeName = 'NEW_PASSWORD_REQUIRED';

export interface Attributes {
  'custom:roleid': string;
  'custom:orgid': string;
  'custom:userid': string;
  sub: string;
  name: string;
  phone_number: string;
  family_name: string;
  email: string;
}

export interface Client {
  endpoint: string;
  fetchOptions: FetchOptions;
}

export interface FetchOptions {}

export interface Pool {
  userPoolId: string;
  clientId: string;
  client: Client;
  advancedSecurityDataCollectionFlag: boolean;
  storage: { [key: string]: string };
}

export interface SignInUserSession {
  idToken: IDToken;
  refreshToken: RefreshToken;
  accessToken: AccessToken;
  clockDrift: number;
}

export interface AccessToken {
  jwtToken: string;
  payload: AccessTokenPayload;
}

export interface AccessTokenPayload {
  sub: string;
  device_key: string;
  token_use: string;
  scope: string;
  auth_time: number;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
  client_id: string;
  username: string;
}

export interface IDToken {
  jwtToken: string;
  payload: IDTokenPayload;
}

export interface IDTokenPayload {
  'cognito:username': string;
  'custom:userid': string;
  'custom:roleid': string;
  'custom:orgid': string;
  sub: string;
  iss: string;
  aud: string;
  token_use: string;
  auth_time: number;
  name: string;
  phone_number: string;
  exp: number;
  iat: number;
  given_name: string;
  family_name: string;
  email: string;
}

export interface RefreshToken {
  token: string;
}

interface ChallengeParam {
  requiredAttributes: string[];
  userAttributes: Omit<Attributes, 'family_name' | 'sub'>;
}

export enum LoggedInUserRole {
  SUPER_ADMIN = 1,
  ADMIN,
  CLASS_TEACHER,
  TEACHER,
  STUDENT,
  RESOURCE,
}

export type FormInView =
  | 'LOGIN'
  | 'FORGOT_PASSWORD'
  | 'FORGOT_PASSWORD_SUBMIT'
  | 'CHANGE_PASSWORD'
  | 'SIGNUP'
  | 'SIGNUP_MODE'
  | 'SET_NEW_PASSWORD';

export interface LoginResult {
  data: CognitoUser | undefined;
  error: CognitoError | undefined;
}

export interface VerifyUserResponse extends ApiResponseItem<string> {}
